.about-portrait {
    max-width: 350px;
    max-height: 525px;
    width: 100%;
    border-radius: 10px;
    opacity: 0;
    animation: slideInRight 1s ease forwards;
}

.about-body {
    display: flex;
    padding: 1em;
    column-gap: 3em;
    justify-content: space-evenly;
    opacity: 0;
    animation: fadeIn 0.8s ease forwards;
}

.about-text {
    max-width: 50%;
    opacity: 0;
    animation: slideInLeft 1s ease forwards;
}

/* Animations */
@keyframes slideInLeft {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media screen and (max-width:775px) {
    .about-body {
        flex-wrap: wrap;
    }
    .about-text {
        max-width: 100%;
    }
}
