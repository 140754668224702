.home-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
    min-height: 100%;
    flex: 1;
    background: linear-gradient(135deg, #f5f7fa 0%, #e8edf5 100%);
}

.home-text {
    flex: 1;
    padding-right: 2rem;
    opacity: 0;
    animation: slideInLeft 1s ease forwards;
}

.home-text h1 {
    font-size: 4rem;
    margin: 0;
    background: linear-gradient(45deg, #2c3e50, #3498db);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 700;
    letter-spacing: -1px;
}

.home-text h2 {
    font-size: 2rem;
    margin: 1rem 0;
    color: #7f8c8d;
    font-weight: 400;
}

.home-portrait {
    max-width: 400px;
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 20px 20px 60px #d1d9e6,
                -20px -20px 60px #ffffff;
    opacity: 0;
    animation: slideInRight 1s ease forwards;
    transition: transform 0.3s ease;
}

.home-portrait:hover {
    transform: translateY(-10px);
}

/* Animations */
@keyframes slideInLeft {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .home-body {
        flex-direction: column;
        text-align: center;
        padding: 2rem;
        gap: 2rem;
    }

    .home-text {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-text h1 {
        font-size: 3rem;
    }

    .home-text h2 {
        font-size: 1.5rem;
    }

    .home-portrait {
        max-width: 300px;
    }
}

