.portfolio-body {
    padding: 2rem;
    max-width: 1000px;
    margin: 0 auto;
}

.portfolio-card {
    margin-bottom: 2rem;
    opacity: 0;
    animation: slideInLeft 0.8s ease forwards;
}

.portfolio-card:nth-child(1) { animation-delay: 0s; }
.portfolio-card:nth-child(2) { animation-delay: 0.2s; }
.portfolio-card:nth-child(3) { animation-delay: 0.4s; }

.portfolio-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@keyframes slideInLeft {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .portfolio-body {
        padding: 1rem;
    }
}
