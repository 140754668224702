.card-container {
    display: flex;
    height: fit-content;
    gap: 1em;
}

.card-title {
    font-size: 20px;
    font-weight: bold;
}

.card-content {
    display: flex;
    flex-direction: column;
}

.card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    margin-top: auto;
}
